import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";


const Legal = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            legal {
              label
              path
            }
          }
        }
      }
    `
  );

  const legal = site.siteMetadata.legal;

  return (
    <nav className="block">
      <ul className="flex flex-col lg:flex-row lg:space-x-8 space-y-2 lg:space-y-0">
        { legal.map(item => (
          <li><Link to={ item.path }>{ item.label }</Link></li>
        ))}
      </ul>
    </nav>
  )
}

export default Legal;
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import Burger from "./Burger";
import SiteNav from "../Navigation/SiteNav";

import BrandIconDark from "../../assets/images/svg/brand-icon-dark.svg";
import BrandIconLight from "../../assets/images/svg/brand-icon-light.svg";
import BrandLogoDark from "../../assets/images/svg/brand-logo-dark.svg";
import BrandLogoLight from "../../assets/images/svg/brand-logo-light.svg";


const Header = ({
  colorScheme
}) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [headerVisible, setHeaderVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setHeaderVisible((prevScrollPos > currentScrollPos) || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, headerVisible, handleScroll]);

  let style = {}

  if (colorScheme === "light") {
    style.header = "fixed top-0 w-full header-height bg-white z-40 text-black transition-all duration-500"
  } else if (colorScheme === "dark") {
    style.header = "fixed top-0 w-full header-height bg-blue-dark z-40 text-white transition-all duration-500";
  }

  return (
    <header
      className={ style.header }
      style={{ top: headerVisible ? '0' : '-120px' }}
    >
      <div className="container mx-auto px-6 py-6 xl:py-8">
        <div className="flex flex-row justify-between items-center">
          { colorScheme === "dark" &&
            <Link
              to="/"
            >
              <BrandLogoLight className="hidden lg:block h-14" />
              <BrandIconLight className="absolute top-6 lg:hidden h-10 header-logo" />
            </Link>
          }
          { colorScheme === "light" &&
            <Link
              to="/"
            >
              <BrandLogoDark className="hidden lg:block h-14" />
              <BrandIconDark className="absolute top-6 lg:hidden h-10 header-logo" />
            </Link>
          }
          <div className="flex flex-row items-end xl:items-center leading-none">
            <div className="hidden xl:block">
              <SiteNav />
            </div>
            <Burger
              colorScheme={ colorScheme }
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import SEO from "../SEO/SEO";


const Layout = ({
  children,
  title,
  description
}) => {
  return (
    <>
      <SEO
        title={ title }
        description={ description }
      />

      <main className="relative w-full h-full overflow-hidden">
        { children }
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default Layout;

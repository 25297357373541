import React from "react";
import { graphql, useStaticQuery } from "gatsby";


const Socials = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socials {
              icon
              link
              alt
            }
          }
        }
      }
    `
  );

  const socials = site.siteMetadata.socials;

  return (
    <nav className="block">
      <ul className="flex flex-row space-x-6">
      { socials.map(item => (
        <li>
          <a href={ item.link } target="_blank" rel="noopener noreferrer">
            <img
              className="h-5"
              src={ item.icon }
              alt={ item.alt }
            />
          </a>
        </li>
      ))}
      </ul>
    </nav>
  )
}

export default Socials;
import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";


const SiteNav = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteNav {
              label
              path
            }
          }
        }
      }
    `
  );

  const siteNav = site.siteMetadata.siteNav;

  return (
    <nav className="block">
      <ul className="flex flex-col xl:space-x-8 space-y-2 xl:space-y-0 xl:flex-row text-2xl xl:text-base font-thin xl:font-normal">
        { siteNav.map(item => (
          <li><Link to={ item.path }>{ item.label }</Link></li>
        ))}
      </ul>
    </nav>
  )
}

export default SiteNav;

import React from "react";
import { Link } from "gatsby";

import SiteNav from "../Navigation/SiteNav";
import Socials from "../Navigation/Socials";
import Legal from "../Navigation/Legal";

import BrandLogoLight from "../../assets/images/svg/brand-logo-light.svg";


const Footer = () => {
  return (
    <footer className="w-full bg-blue-dark text-white">
      <div className="container mx-auto">
        <div className="mx-6 pt-8 pb-4 xl:py-8 xl:border-b xl:border-gray-400">
          <div className="flex flex-row justify-between items-center">
            <Link to="/">
              <BrandLogoLight className="h-14" />
            </Link>
            <div className="hidden xl:block">
              <SiteNav />
            </div>
            <div className="hidden lg:block">
              <Socials />
            </div>
          </div>
        </div>
        <div className="container mx-auto px-6 pt-4 pb-8 lg:py-8">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center space-y-4 lg:space-y-0 text-sm font-thin text-gray-400">
            <Legal />
            <div>
              <span>&copy; { new Date().getFullYear() } KND Complex Litigation. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
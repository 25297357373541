import React, { useEffect } from "react";
import { Link } from "gatsby";
import gsap, { Power4, TimelineMax } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";

import SiteNav from "../Navigation/SiteNav";
import Socials from "../Navigation/Socials";

import BrandIconLight from "../../assets/images/svg/brand-icon-light.svg";


const Burger = ({
  colorScheme
}) => {
  useEffect(() => {
    gsap.registerPlugin(CSSPlugin);

    const openTrigger = document.querySelector(".menu-trigger");
    const openTriggerTop = document.querySelector(".menu-trigger-bar-top");
    const openTriggerMiddle = document.querySelector(".menu-trigger-bar-middle");
    const openTriggerBottom = document.querySelector(".menu-trigger-bar-bottom");

    const closeTrigger = document.querySelector(".close-trigger");
    const closeTriggerLeft = document.querySelector(".close-trigger-bar-left");
    const closeTriggerRight = document.querySelector(".close-trigger-bar-right");

    const innerContainer = document.querySelector(".inner-container");
    const menu = document.querySelector(".menu");
    const menuTop = document.querySelector(".menu-bg-top");
    const menuMiddle = document.querySelector(".menu-bg-middle");
    const menuBottom = document.querySelector(".menu-bg-bottom");

    const tlOpen = new TimelineMax({ paused: true });
    const tlClose = new TimelineMax({ paused: true });

    tlOpen.add("preOpen")
      .to(openTriggerTop, {
        duration: 0.4,
        x: "+80px",
        y: "-80px",
        delay: 0.1,
        ease: Power4.easeIn,
        onComplete: function() {
          closeTrigger.style.zIndex = "25";
        }
      }, "preOpen")
      .to(openTriggerMiddle, {
        duration: 0.4,
        x: "+=80px",
        y: "-=80px",
        ease: Power4.easeIn,
        onComplete: function() {
          openTrigger.style.visibility = "hidden";
        }
      }, "preOpen")
      .to(openTriggerBottom, {
        duration: 0.4,
        x: "+=80px",
        y: "-=80px",
        delay: 0.2,
        ease: Power4.easeIn
      }, "preOpen")
      .fromTo(innerContainer, {
        display: "none"
      }, {
        duration: 0.1,
        opacity: 1,
        display: "block",
        ease: Power4.easeOut
      }, "-=0.2")
      .add("open", "-=0.4")
      .to(menuTop, {
        duration: 0.8,
        scaleY: 1,
        ease: Power4.easeInOut
      }, "open")
      .to(menuMiddle, {
        duration: 0.8,
        scaleY: 1,
        ease: Power4.easeInOut
      }, "open")
      .to(menuBottom, {
        duration: 0.8,
        scaleY: 1,
        ease: Power4.easeInOut
      }, "open")
      .fromTo(menu, {
        y: 30,
        opacity: 0,
        display: "none"
      }, {
        duration: 0.6,
        y: 0,
        opacity: 1,
        display: "block",
        ease: Power4.easeOut
      }, "-=0.2")
      .add("preClose", "-=0.8")
      .to(closeTriggerLeft, {
        duration: 0.8,
        x: "-=100px",
        y: "+=100px",
        ease: Power4.easeOut
      }, "preClose")
      .to(closeTriggerRight, {
        duration: 0.8,
        x: "+=100px",
        y: "+=100px",
        delay: 0.2,
        ease: Power4.easeOut
      }, "preClose");
    
    tlClose.add("close")
      .to(menuTop, {
        duration: 0.2,
        backgroundColor: "#142538",
        ease: Power4.easeInOut,
        onComplete: function() {
          closeTrigger.style.zIndex = "5";
          openTrigger.style.visibility = "visible";
        }
      }, "close")
      .to(menuMiddle, {
        duration: 0.2,
        backgroundColor: "#142538",
        ease: Power4.easeInOut
      }, "close") 
      .to(menuBottom, {
        duration: 0.2,
        backgroundColor: "#142538",
        ease: Power4.easeInOut
      }, "close")
      .to(menu, {
        duration: 0.6,
        y: 20,
        opacity: 0,
        ease: Power4.easeOut,
        onComplete: function() {
          menu.style.display = "none";
        }
      }, "close")
      .to(menuTop, {
        duration: 0.8,
        scaleY: 0,
        ease: Power4.easeInOut
      }, "close", "+=0.2")
      .to(menuMiddle, {
        duration: 0.8,
        scaleY: 0,
        ease: Power4.easeInOut
      }, "close", "+=0.2")
      .to(menuBottom, {
        duration: 0.8,
        scaleY: 0,
        ease: Power4.easeInOut,
        onComplete: function() {
          menuTop.style.backgroundColor = "#142538";
          menuMiddle.style.backgroundColor = "#142538";
          menuBottom.style.backgroundColor = "#142538";
        }
      }, "close", "+=0.2")
      .to(closeTriggerLeft, {
        duration: 0.2,
        x: "+=100px",
        y: "-=100px",
        ease: Power4.easeIn
      }, "close")
      .to(closeTriggerRight, {
        duration: 0.2,
        x: "-=100px",
        y: "-=100px",
        delay: 0.1,
        ease: Power4.easeIn
      }, "close")
      .to(openTriggerTop, {
        duration: 1,
        x: "-=80px",
        y: "+=80px",
        delay: 0.2,
        ease: Power4.easeOut
      }, "close")
      .to(openTriggerMiddle, {
        duration: 1,
        x: "-=80px",
        y: "+=80px",
        ease: Power4.easeOut
      }, "close")
      .to(openTriggerBottom, {
        duration: 1,
        x: "-=80px",
        y: "+=80px",
        delay: 0.1,
        ease: Power4.easeOut
      }, "close")
      .to(innerContainer, {
        duration: 0.1,
        ease: Power4.easeOut,
        onComplete: function() {
          innerContainer.style.display = "none";
        }
      }, "+=0.2");
    
    openTrigger.onclick = function() {
      if (tlOpen.progress() < 1) {
        tlOpen.play();
      } else {
        tlOpen.restart();
      }
    };
            
    closeTrigger.onclick = function() {
      if (tlClose.progress() < 1) {
        tlClose.play();
      } else {
        tlClose.restart();
      }
    };
  }, []);

  return (
    <div className="xl:hidden">
      { colorScheme === "dark" &&
        <span className="menu-trigger">
          <div className="menu-trigger-bar menu-trigger-bar-dark menu-trigger-bar-top"></div>
          <div className="menu-trigger-bar menu-trigger-bar-dark menu-trigger-bar-middle"></div>
          <div className="menu-trigger-bar menu-trigger-bar-dark menu-trigger-bar-bottom"></div>
        </span>
      }
      { colorScheme === "light" &&
        <span className="menu-trigger">
          <div className="menu-trigger-bar menu-trigger-bar-light menu-trigger-bar-top"></div>
          <div className="menu-trigger-bar menu-trigger-bar-light menu-trigger-bar-middle"></div>
          <div className="menu-trigger-bar menu-trigger-bar-light menu-trigger-bar-bottom"></div>
        </span>
      }
      <span className="close-trigger">
        <div className="close-trigger-bar close-trigger-bar-left"></div>
        <div className="close-trigger-bar close-trigger-bar-right"></div>
      </span>
      <div className="inner-container">
        <div className="menu-bg menu-bg-top"></div>
        <div className="menu-bg menu-bg-middle"></div>
        <div className="menu-bg menu-bg-bottom"></div>
        <div className="menu-container">
          <div className="menu container mx-auto h-full text-white">
            <Link to="/">
              <BrandIconLight className="absolute top-6 left-6 h-10" />
            </Link>
            <div className="flex flex-col justify-between items-start h-full px-6 pt-28 sm:pt-36 pb-6">
              <SiteNav />
              <div>
                <div className="text-lg font-thin">
                  <span className="block underline"><a href="mailto:contact@knd.law">contact@knd.law</a></span>
                  <span className="block"><a href="tel:+14165373529">+1 416 537 3529</a></span>
                </div>
              </div>
              <div>
                <div className="text-lg">
                  <span className="block">Toronto</span>
                  <span className="block font-thin">1186 Eglinton Ave W</span>
                  <span className="block font-thin">M6C 2E3</span>
                </div>
              </div>
              <Socials />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Burger;